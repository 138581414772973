import type {
  ICategoryPreviewsResponse,
  ICategorySectionResponse,
  IEverGreenBanner,
  IMagazine,
  IMainSlide,
  IOwnBrand,
  ISpecialOffer,
  ITicker,
} from "~/typings/api/sections";
import { urls } from "~/api/config";
import { getCachedData } from "~/utils/cached-data";

export default () => {
  const commonParams = {
    lazy: true,
    getCachedData: getCachedData,
  };

  const requestMainSliderSlides = () =>
    useTransport<IMainSlide[]>(urls.sections.mainSlides, commonParams);
  const requestEverGreenBanners = () =>
    useTransport<IEverGreenBanner[], IEverGreenBanner[]>(
      urls.sections.everGreenBanners,
      commonParams,
    );
  const requestTicker = () => useTransport<ITicker, ITicker>(urls.sections.ticker, commonParams);
  const requestCategoryPreviews = () =>
    useTransport<ICategoryPreviewsResponse, ICategoryPreviewsResponse>(
      urls.sections.categories,
      commonParams,
    );
  const requestSpecialOffers = () =>
    useTransport<ISpecialOffer[], ISpecialOffer[]>(urls.sections.specialOffers, commonParams);
  const requestMagazine = () =>
    useTransport<IMagazine, IMagazine>(urls.sections.magazine, commonParams);
  const requestOwnBrands = () => useTransport<IOwnBrand[]>(urls.sections.ownBrands, commonParams);
  const requestNewArrival = () =>
    useTransport<ICategorySectionResponse>(urls.sections.newArrival, commonParams);

  return {
    requestMainSliderSlides,
    requestEverGreenBanners,
    requestTicker,
    requestCategoryPreviews,
    requestSpecialOffers,
    requestMagazine,
    requestOwnBrands,
    requestNewArrival,
  };
};
