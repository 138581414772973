<script setup lang="ts">
import { vIntersectionObserver } from "@vueuse/components";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VYaSlot from "@magnit/core/src/containers/VYaSlot/VYaSlot.vue";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import { Swiper as SwiperClass } from "swiper";
import { IYaSlotApiStatus, useSlotsStore } from "~/stores/useSlotsStore";

const { send } = useAnalytics();
const swiper = ref<Nullable<SwiperClass>>();
const { adfoxOwnerId } = usePublicConfig();
const slotApi = useSlotsStore();
const { requestSpecialOffers } = sectionsApi();
const { pending, data: slides } = await requestSpecialOffers();

const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) =>
  isIntersecting && send("Sections:SpecialOffers:View");

const onSwiper = (s: SwiperClass) => (swiper.value = s);

const stubedSlides = ref<number[]>([]);
const onSlideStab = (id: number) => {
  stubedSlides.value.push(id);
};
const displaySlides = computed(() =>
  slides.value?.filter((i) => !stubedSlides.value.includes(i.id)),
);
</script>

<template>
  <SectionsSpecialOffersSkeleton v-if="pending || !slotApi.slotApiLoaded" />
  <section
    v-else-if="displaySlides?.length"
    v-intersection-observer="onVisibility"
    class="special-offers"
  >
    <div class="special-offers__title-wrapper">
      <VWrapperLocal>
        <VText :font="{ xs: 'headline-small', l: 'headline-large' }">
          Спецпредложения
        </VText>
      </VWrapperLocal>
    </div>
    <VWrapper :indent="{ l: '32px' }">
      <AppSlider
        autoplay
        data-test-id="special-offers"
        :breakpoints="{
          xs: {
            slidesPerView: 'auto',
            spaceBetween: 12,
            slidesOffsetAfter: 12,
            slidesOffsetBefore: 12,
            navigation: false,
            pagination: {
              enabled: true,
            },
          },
          s: {
            slidesPerView: 'auto',
            spaceBetween: 12,
            slidesOffsetAfter: 20,
            slidesOffsetBefore: 20,
            navigation: false,
            pagination: {
              enabled: true,
            },
          },
          m: {
            slidesPerView: 'auto',
            spaceBetween: 24,
            slidesOffsetAfter: 32,
            slidesOffsetBefore: 32,
            navigation: false,
            pagination: {
              enabled: true,
            },
          },
          l: {
            slidesPerView: 4,
            spaceBetween: 24,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            navigation: true,
            pagination: {
              enabled: true,
            },
          },
          xl: {
            slidesPerView: 4,
            spaceBetween: 24,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            navigation: true,
            pagination: {
              enabled: true,
            },
          },
        }"
        @on-swiper="onSwiper"
      >
        <AppSliderSlide
          v-for="(item, index) in displaySlides"
          :key="item.id"
          :size="{ xs: 6, m: 4 }"
        >
          <VYaSlot
            v-if="item?.adfoxPayload"
            :api-ready="slotApi.slotStatus === IYaSlotApiStatus.SUCCESS"
            :api-error="slotApi.slotStatus === IYaSlotApiStatus.ERROR"
            :owner-id="adfoxOwnerId"
            :config="item.adfoxPayload"
            :analytics-sender="send"
            :analytics-view="{
              name: 'Sections:SpecialOffers:Item:View',
              options: {
                position: index + 1,
              },
            }"
            :analytics-click="{
              name: 'Sections:SpecialOffers:Item:Click',
              options: {
                position: index + 1,
              },
            }"
            with-hover
            @stub="onSlideStab(item.id)"
          />
        </AppSliderSlide>
      </AppSlider>
    </VWrapper>
  </section>
</template>

<style scoped lang="postcss">
.special-offers {
  /* min-height прописаны чтобы уменьшить прыжки страницы пока картинки слайдов грузятся в Adfox */
  min-height: 200px;

  @media (--pl-viewport-from-ml) {
    min-height: 240px;
  }

  @media (--pl-viewport-from-l) {
    min-height: 284px;
  }

  &__title-wrapper {
    padding-top: var(--pl-unit-x8);
    padding-bottom: var(--pl-unit-x2);
  }

  @media (--pl-viewport-from-ml) {
    padding-bottom: var(--pl-unit-x4);
  }

  :deep(.slider-slide) {
    overflow: hidden;
    border-radius: var(--pl-unit-x4);

    @media (--pl-viewport-from-ml) {
      border-radius: var(--pl-unit-x5);
    }

    @media (--pl-viewport-from-xl) {
      border-radius: var(--pl-unit-x6);
    }
  }
}

@media (--pl-viewport-from-l) {
  .special-offers {
    &__title-wrapper {
      padding-top: calc(var(--pl-unit-x6) * 2);
      padding-bottom: var(--pl-unit-x4);
    }
  }
}
</style>
